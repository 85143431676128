<template>
  <div class="sub-menu">
    <div class="nav-bar">
      <div class="menu-links">
        <!-- v-if="checkPermission('SGCDG')" -->
        <div class="menu-link">
          <router-link class="menu-item" to="/">
            Statistique facture
          </router-link>
        </div>
        <div class="menu-link" v-if="checkPermission('GOTHS')">
          <router-link class="menu-item" to="/statistic-oblige">
            Statistique obligés
          </router-link>
        </div>
        <div class="notif-export-file" v-if="getShowNotifExportFile">
          Préparation du fichier en cours
        </div>
      </div>
    </div>
    <div class="body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['checkPermission', 'getShowNotifExportFile'])
  }
};
</script>

<style scoped lang="scss"></style>
